$.fn.timeline = function(datas) {
    $(this).each(function(){

        var $select = $(this);
            for (var key in datas){
                var details = datas[key];
                $select.html($select.html()+'<div class="row_key" style="height:' +details[0]+'px">' +
                                '<div class="bar" style="background:' +details[1]+'"></div>' +
                                '<div class="year">'+details[2]+'</div>' +
                                '<div class="details_wrap" >' +
                                    '<div class="circle" style="background:' +details[1]+'"></div>'+
                                    '<div class="details" >' +
                                        '<div class="main_title">' +details[3]+'</div>' +
                                        '<div class="icon ' +details[4]+'"></div>' +
                                        '<div class="full_details">' +details[5]+'</div>' +
                                    '</div>' +
                                '</div>' +
                            '</div>') ;
            }



    })

};