/*$( document ).ready(function() {
    new BoxesFx( document.getElementById( 'boxgallery' ) );

});*/


$.fn.split_slider = function(options) {

    $(this).each(function(){
        new BoxesFx( $(this)[0] );
        if (typeof options.rotation != 'undefined'){
            var $next = $(this).find('.next');
            var t = setInterval(function(){
                $next.click();
            },options.rotation );
        }
        if (typeof options.display_arrow != 'undefined' && options.display_arrow==false ) {
            $(this).find('.next, .prev').hide();
        }
    })

};