
$( document ).ready(function() {


    //---------------------------
    //Fixed header
    //---------------------------
    var $header = $("header");
    var $body = $("body");
    var $content = $(".content");
    var header_height = $header.height() ;


    var isShowing = false;
    var is_skill_init = false;
    $(window).scroll(function(e) {
        if ($body.scrollTop() > header_height ) {
            $header.addClass('fixed');
            //$content.css('margin-top', header_height );
            //animation
            if (isShowing == false){
                isShowing = true;
                //$header.css('margin-top', '-'+header_height );
                $header.css('margin-top', '-'+$("header").height()+'px' );
                $header.stop().animate({ marginTop: '0' }, 300);
            }

        } else {
            $header.removeClass('fixed');
            //$content.css('margin-top', '0' );
            $header.stop().css('margin-top', '0' );
            isShowing = false;

        }


        //---------------------------------
        // Text effect
        //---------------------------------
        $('.text_hover_effect ').each(function(){
            if(isElementInViewport($(this), 100, 100) ){
                $(this).addClass('active');
            }
            else{
                $(this).removeClass('active');
            }
        });

        //---------------------------------
        // animation skills
        //---------------------------------
        if( is_skill_init == false && ( isElementInViewport($('#change_style '), 100, 100) ||  $(window).width() < 1000)  ){
            is_skill_init = true;
            if ($(window).width() < 1000 ){
                init_alternative_skill();
            }else{       
                init_skill();
                animate();
            }
        }

        
    });

    



    //---------------------------------
    // letter svg
    //---------------------------------
    var slider_time = 4000;
   // var loading_delay = 3500;
    var loading_delay = 3000;


    //Loading
   // var size_loading = ($(window).height() < $(window).width() ? $(window).height() / 3 :  $(window).width() / 3);
    var size_loading = ($(window).height() < $(window).width() ? $(window).height() / 8 :  $(window).width() / 8);
    var loading_options = {
        size: size_loading,         // Font size, defined by the height of the letters (pixels)
        weight: 5,         // Font weight (pixels)
        rounded: false,    // Rounded letter endings
        color: ['#fff','#fff','#fff'],  // Font color
        duration: 1,       // Duration of the animation of each letter (seconds)
        //delay: [0, 0.05],  // Delay animation among letters (seconds)
        delay: [0.3, 0, 0.2 ,0.5,0.1],  // Delay animation among letters (seconds)
        fade: 0.5,         // Fade effect duration (seconds)
        easing: d3_ease.easeCubicInOut.ease,   // Easing function
        //individualDelays: false,               // If false (default), every letter delay increase gradually, showing letters from left to right always. If you want to show letters in a disorderly way, set it to true, and define different delays for the desired letters.
        individualDelays: true,               // If false (default), every letter delay increase gradually, showing letters from left to right always. If you want to show letters in a disorderly way, set it to true, and define different delays for the desired letters.
    };

    //init
    $('.animated_loading').letter_svg(loading_options);
    $('.animated_loading').letter_svg_show();
    disableScroll();

    setTimeout(function(){
        $('.animated_loading').letter_svg_hide();
        $('.loading_hero').addClass('done');
        window.scrollTo(0, 0);
        setTimeout(function(){
            enableScroll();
        }, 1000);
    }, 2000);


    //Main Hero
    //var size_hero = ($(window).height() < $(window).width() ? $(window).height() / 4 :  $(window).width() / 4);
    var size_hero = ($(window).height() < $(window).width() ? $(window).height() / 8 :  $(window).width() / 8);
    //console.log(size_hero);
    var hero_options = {
        size: size_hero,         // Font size, defined by the height of the letters (pixels)
        weight: 3,         // Font weight (pixels)
        rounded: false,    // Rounded letter endings
        color: ['#fff','#fff','#fff'],  // Font color
        duration: 1,       // Duration of the animation of each letter (seconds)
        //delay: [0, 0.05],  // Delay animation among letters (seconds)
        delay: [0.3, 0, 0.2 ,0.5,0.1],  // Delay animation among letters (seconds)
        fade: 0.5,         // Fade effect duration (seconds)
        easing: d3_ease.easeCubicInOut.ease,   // Easing function
        //individualDelays: false,               // If false (default), every letter delay increase gradually, showing letters from left to right always. If you want to show letters in a disorderly way, set it to true, and define different delays for the desired letters.
        individualDelays: true,               // If false (default), every letter delay increase gradually, showing letters from left to right always. If you want to show letters in a disorderly way, set it to true, and define different delays for the desired letters.
    };
    //init
    var main_hero_text = {
        1 : ['Full Stack','Developer'],
       // 1 : ['abcdefghijk', 'lmopqrstuvwxyZ'],
        2 : ['In Web','Technologies'],
        3 : ['Experienced', 'And Reliable'],
        4 : ['Check Out','Below'],
    }
    for (var k in main_hero_text){
        $('.animated_hero_title').html($('.animated_hero_title').html()+'<div class="animated_hero_title_wrap" text_num="'+k+'"><div class="animated_hero_title_1">'+main_hero_text[k][0]+'</div><div class="animated_hero_title_2">'+main_hero_text[k][1]+'</div></div>');
    }
    $('.animated_hero_title_1,.animated_hero_title_2').letter_svg(hero_options);

    setTimeout(function(){
        $('.main_hero ').addClass('active');
        init_text_slider();
    }, loading_delay);

    function init_text_slider(){
        $('.main_hero ').addClass('active');
        rotate_text()
        setInterval(rotate_text,slider_time);

    }
    function rotate_text() {

        var next_text_num = 1;
        var $current_text = $('.animated_hero_title_wrap.active').removeClass('active');

        if ($current_text.length){
            var current_text_num = parseInt($current_text.attr('text_num'));
            $current_text.find('.animated_hero_title_1, .animated_hero_title_2').letter_svg_hide();
            if (parseInt(current_text_num) < Object.keys(main_hero_text).length ){
                next_text_num = current_text_num + 1;
            }

        }
        setTimeout(function(){
            $('.animated_hero_title_wrap[text_num='+next_text_num+']').addClass('active').find('.animated_hero_title_1, .animated_hero_title_2').letter_svg_show();
        },1000);

    }


    //---------------------------------
    // Slider
    //---------------------------------
    $('#boxgallery').split_slider({
        rotation : slider_time,
        display_arrow: false,
    });



    //---------------------------------
    // Arrow
    //---------------------------------
    $('.arrow_more .fa').click(function(){
        $('html, body').animate({
            scrollTop: $('.section').first().offset().top
        }, 1000);
    });

    //---------------------------------
    // timeline
    //---------------------------------

   /* var timeline_data = {
        '2006' : ['fa fa-star', 'Hight scool certificate', 'Science section'],
        '2009' : ['fa fa-star', 'Advanced diploma', 'Specialisation in Network Engineering'],
        '20013' : ['fa fa-star', 'Advanced diploma', 'Specialisation in Network Engineering'],

    };*/
    var timeline_data = {
        1 : ['170' , '#d1652f' ,'2013' , 'Engineer\'s Degree', 'fa fa-graduation-cap', 'Specialisation in Development Engineering<br><i><b>Ingesup</b></i> (France)'],
        2 : ['140' ,'#b1c482' ,'2009' ,  'Advanced diploma', 'fa fa-desktop','Specialisation in Network Engineering<br><i><b>IUT R&T</b></i> (France)'],
        3 : ['200','#6dbacc','2006',  'High school certificate', 'fa fa-flask', 'Science section<br><i><b>Thiers</b></i> (France)'],

    };
    $('.timeline_wrap').timeline(timeline_data);


    //---------------------------------
    // skills
    //---------------------------------

    // 'title', 'subtitle', 'top right titile' , col , line
    var stars = function(n){
        var stars_html = '';
        for(var i = 0 ; i < n ; i++){
            stars_html += '<i class="fa fa-star" aria-hidden="true"></i>';
        }
        return stars_html;
    }
    var table = [



        //Col 3
        "jQuery", stars(5), "Advanced", 7, 1,
        "Git", stars(5), "Advanced", 7, 2,
        "MVC", stars(5), "Advanced", 7, 3,
        "Agile", stars(3), "Advanced", 7, 4,
        "Mandrill", stars(4), "Advanced", 7, 5,

        "JSON", stars(5), "Advanced", 8, 1,
        "Ajax", stars(5), "Advanced", 8, 2,
        "Angular 2", stars(3), "Advanced", 8, 3,
        "AWS", stars(3), "Advanced", 8, 4,
        "Sockets", stars(5), "Advanced", 8, 5,

        //Col 2
        "Laravel 5", stars(4), "pro", 4, 1,
        "Bootstrap", stars(4), "pro", 4, 2,
        "API", stars(5), "pro", 4, 3,
        "Gulp", stars(5), "pro", 4, 4,
        "Magento", stars(3), "pro", 4, 5,

        "Wordpress", stars(5), "pro", 5, 1,
        "Responsive", stars(5), "pro", 5, 2,
        "Android", stars(3), "pro", 5, 3,
        "Composer", stars(5), "pro", 5, 4,
        "VS Code", stars(5), "pro", 5, 5,



        //col1
        "PHP 7", stars(5), "nul", 1, 1,
        "HTML 5", stars(5), "Advanced", 1, 2,
        "CSS 3", stars(5), "Advanced", 1, 3,
        "SQL", stars(5), "Advanced", 1, 4,
        "Java", stars(4), "Advanced", 1, 5,

        "Javascript", stars(5), "Advanced", 2, 1,
        "XML", stars(5), "Advanced", 2, 2,
        "SASS", stars(5), "pro", 2, 3,
        "LESS", stars(5), "pro", 2, 4,
        "Flash", stars(3), "Advanced", 2, 5,


    ];

    function init_alternative_skill() {
        
        $('#change_style').remove();
        $('.skills_effect_wrap').addClass('alternate_skill');
        var new_html = '<div class="contain">';
        //var bg_color = rgba(1, 72, 69, 0.9
        for ( var i = 0; i < table.length; i += 5 ) {
            
            
            new_html += '<div class="alternate_el_wrap"><div class="alternate_el" style="background-color:rgba('+(1 + i/3 )+', '+(72)+' , '+ (69+i/2) +', 0.9)">\
                                            <div class="name">'+table[ i  ]+'</div>\
                                            <div class="rating">'+table[ i + 1  ]+' </div>\
                        </div></div>';
        }
        $('#'+effect_area).append(new_html + '</div>');
        
    }

    
    var el_width = 550;
    var el_height = 300;
    var spacing = 60;
    var effect_area = 'skills_effect';
    var camera, scene, renderer;
    var controls;
    var objects = [];
    var targets = { table: [], sphere: [], helix: [], grid: [] };

    function init_skill() {
        camera = new THREE.PerspectiveCamera( 40,$('#'+effect_area).innerWidth()/ $('#'+effect_area).innerHeight() , 1, 10000 );
        camera.position.z = 5000;
        scene = new THREE.Scene();
        // table
        for ( var i = 0; i < table.length; i += 5 ) {
            var element = document.createElement( 'div' );
            element.className = 'element';
            //element.style.backgroundColor = 'rgba(0,127,127,' + ( Math.random() * 0.5 + 0.25 ) + ')';
            var number = document.createElement( 'div' );
            number.className = 'number';
            number.textContent = (i/5) + 1;
            element.appendChild( number );
            var symbol = document.createElement( 'div' );
            symbol.className = 'symbol';
            symbol.textContent = table[ i ];
            element.appendChild( symbol );
            var details = document.createElement( 'div' );
            details.className = 'details';
            details.innerHTML = '<div class="details_1">'+table[ i + 1 ]+'</div><div class="details_2">'+table[ i + 2 ]+'</div>';
            element.appendChild( details );
            var object = new THREE.CSS3DObject( element );
            object.position.x = Math.random() * 4000 - 2000;
            object.position.y = Math.random() * 4000 - 2000;
            object.position.z = Math.random() * 4000 - 2000;
            scene.add( object );
            objects.push( object );
            //
            var ajust_height = 700;
            var ajust_width = 2800;
            var object = new THREE.Object3D();
            //object.position.x = ( table[ i + 3 ] * (el_width + spacing) ) - 2330;
            object.position.x = ( table[ i + 3 ] * (el_width + spacing) ) - ajust_width;
            //object.position.y = - ( table[ i + 4 ] * (el_height + spacing)) + 990;
            object.position.y = - ( table[ i + 4 ] * (el_height + spacing)) + ajust_height;
            targets.table.push( object );
        }
        // sphere
        var vector = new THREE.Vector3();
        for ( var i = 0, l = objects.length; i < l; i ++ ) {
            var phi = Math.acos( -1 + ( 2 * i ) / l );
            var theta = Math.sqrt( l * Math.PI ) * phi;
            var object = new THREE.Object3D();
            //var sphere_size = 800;
            var sphere_size = 900;
            var ajust_height = 500;
            object.position.x = sphere_size * Math.cos( theta ) * Math.sin( phi ) ;
            object.position.y = sphere_size * Math.sin( theta ) * Math.sin( phi ) - ajust_height  ;
            object.position.z = sphere_size * Math.cos( phi ) ;
            vector.copy( object.position ).multiplyScalar( 2 );
            object.lookAt( vector );
            targets.sphere.push( object );
        }
        // helix
        var vector = new THREE.Vector3();
        for ( var i = 0, l = objects.length; i < l; i ++ ) {
           // var phi = i * 0.175 + Math.PI;
            var phi = i * 0.675 + Math.PI;
            var object = new THREE.Object3D();
           // var helix_size = 900;
            var helix_size = 900;
            var ajust_height = 400;
            object.position.x = helix_size * Math.sin( phi );
            //object.position.y = - ( i * 8 ) + 450;
            object.position.y = - ( i * 50 ) + ajust_height;
            object.position.z = helix_size * Math.cos( phi );
           // vector.x = object.position.x * 2;
            vector.x = object.position.x * 2;
            vector.y = object.position.y ;
            //vector.z = object.position.z * 2;
            vector.z = object.position.z * 2;
            object.lookAt( vector );
            targets.helix.push( object );
        }
        // grid
        for ( var i = 0; i < objects.length; i ++ ) {
            var object = new THREE.Object3D();
            var item_per_line = 3;
            var ajust_height = 100;
            //object.position.x = ( ( i % 5 ) * 400 ) - 800;
            object.position.x = ( ( i % item_per_line ) * (el_width + spacing) ) - el_width;
            //object.position.y = ( - ( Math.floor( i / 5 ) % 5 ) * 400 ) + 800;
            object.position.y = ( - ( Math.floor( i / item_per_line ) % item_per_line ) * (el_width + spacing) ) + ajust_height;
            //object.position.z = ( Math.floor( i / 25 ) ) * 1000 - 2000;
            object.position.z = ( Math.floor( i / 9 ) ) * 1000 - 2000;
            targets.grid.push( object );
        }
        //
        renderer = new THREE.CSS3DRenderer();
        renderer.setSize( $('#'+effect_area).innerWidth(), $('#'+effect_area).innerHeight() );
        renderer.domElement.style.position = 'absolute';
        document.getElementById( effect_area ).appendChild( renderer.domElement );
        //
        controls = new THREE.TrackballControls( camera, renderer.domElement );
        //controls.rotateSpeed = 0.5;
        controls.rotateSpeed = 10;
       // controls.minDistance = 500;
        controls.minDistance = 1000;
        //controls.maxDistance = 6000;
        controls.maxDistance = 6000;


        controls.addEventListener( 'change', render );
        var button_table = document.getElementById( 'table' );
        button_table.addEventListener( 'click', function ( event ) {
            transform( targets.table, 2000 );
            $('#change_style button').removeClass('active');
            $('#table').addClass('active');

        }, false );
        var button_sphere = document.getElementById( 'sphere' );
        button_sphere.addEventListener( 'click', function ( event ) {
            transform( targets.sphere, 2000 );
            $('#change_style button').removeClass('active');
            $('#sphere').addClass('active');
        }, false );
        var button_helix = document.getElementById( 'helix' );
        button_helix.addEventListener( 'click', function ( event ) {
            transform( targets.helix, 2000 );
            $('#change_style button').removeClass('active');
            $('#helix').addClass('active');
        }, false );
        var button_grid = document.getElementById( 'grid' );
        button_grid.addEventListener( 'click', function ( event ) {
            transform( targets.grid, 2000 );
            $('#change_style button').removeClass('active');
            $('#grid').addClass('active');
        }, false );

        if ( $(window).width() < 1200 ){
                transform( targets.helix, 2000 );
                $('#change_style button').removeClass('active');
                $('#helix').addClass('active');
        }
        else{transform( targets.table, 2000 );}

        
        //
        window.addEventListener( 'resize', onWindowResize, false );
    }
    function transform( targets, duration ) {
        TWEEN.removeAll();
        for ( var i = 0; i < objects.length; i ++ ) {
            var object = objects[ i ];
            var target = targets[ i ];
            new TWEEN.Tween( object.position )
                .to( { x: target.position.x, y: target.position.y, z: target.position.z }, Math.random() * duration + duration )
                .easing( TWEEN.Easing.Exponential.InOut )
                .start();
            new TWEEN.Tween( object.rotation )
                .to( { x: target.rotation.x, y: target.rotation.y, z: target.rotation.z }, Math.random() * duration + duration )
                .easing( TWEEN.Easing.Exponential.InOut )
                .start();
        }
        new TWEEN.Tween( this )
            .to( {}, duration * 2 )
            .onUpdate( render )
            .start();
    }
    function onWindowResize() {
        camera.aspect = $('#'+effect_area).innerWidth()/ $('#'+effect_area).innerHeight() ;
        camera.updateProjectionMatrix();
        renderer.setSize( $('#'+effect_area).innerWidth(), $('#'+effect_area).innerHeight()  );
        render();
    }
    function animate() {
        requestAnimationFrame( animate );
        TWEEN.update();
        controls.update();
    }
    function render() {
        renderer.render( scene, camera );
    }

    //cursor
    /*$(".section_skills").live("mousedown", function (e) {
        e.preventDefault();
        $(this).addClass("mouseDown");
        console.log('mouseDown');
    }).live("mouseup", function () {
        console.log('mouseup');
        $(this).removeClass("mouseDown");
    });
*/

    /*$(".section_skills").mouseup(function() {
            console.log('mouseup');
            $(this).removeClass("mouseDown");
        }).mousedown(function() {
        console.log('mouseDown');
            $(this).addClass("mouseDown");
        });*/


    ///-----------------------------------
    //  Form effect
    //-----------------------------------

        // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
        if (!String.prototype.trim) {
            (function() {
                // Make sure we trim BOM and NBSP
                var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
                String.prototype.trim = function() {
                    return this.replace(rtrim, '');
                };
            })();
        }

        [].slice.call( document.querySelectorAll( '.input__field' ) ).forEach( function( inputEl ) {
            // in case the input is already filled..
            if( inputEl.value.trim() !== '' ) {
                classie.add( inputEl.parentNode, 'input--filled' );
            }

            // events:
            inputEl.addEventListener( 'focus', onInputFocus );
            inputEl.addEventListener( 'blur', onInputBlur );
        } );

        function onInputFocus( ev ) {
            classie.add( ev.target.parentNode, 'input--filled' );
        }

        function onInputBlur( ev ) {
            if( ev.target.value.trim() === '' ) {
                classie.remove( ev.target.parentNode, 'input--filled' );
            }
        }

    ///-----------------------------------
    //  Submit 
    //-----------------------------------
        [].slice.call( document.querySelectorAll( 'button.progress-button' ) ).forEach( function( bttn ) {
            new ProgressButton( bttn, {
                callback : function( instance ) {

                    $('.form_wrap .input').removeClass('error');
                    $('.form_wrap input[type=text], .form_wrap textarea').each(function(){
                        if($(this).val() == ''){
                            $(this).closest('.input').addClass('error');
                        }

                    })
                        .promise()
                        .done( function() {
                            if (!$('.form_wrap .error').length){

                                    var formData = {
                                        'name'       : $('input[name=name]').val(),
                                        'email'      : $('input[name=email]').val(),
                                        'message'    : $('textarea[name=message]').val(),
                                        '_token'    : $('input[name=_token]').val()
                                    };
                                    $.ajax({
                                        url: '/ajax/contact',
                                        type: 'POST',
                                        dataType  : 'json',
                                        data: formData,
                                    }).done(function( data ) {
                                        console.log(data);
                                    });


                                    var progress = 0,
                                    interval = setInterval( function() {
                                        progress = Math.min( progress + Math.random() * 0.5, 1 );
                                        instance._setProgress( progress );

                                        if( progress === 1 ) {
                                            instance._stop(1);
                                            clearInterval( interval );
                                            $('.form_wrap').css('height', $('.form_wrap').outerHeight()+'px');
                                            $('.form_wrap form').fadeOut(1000);
                                            $('.form_wrap .success').delay(1000).fadeIn(1500);

                                        }
                                    }, 200 );

                            }
                            else{
                                instance._stop(-1);
                            }

                        });



                }
            } );
        } );


});